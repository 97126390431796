import React, { Suspense } from 'react';
import '../styling/main.scss';
import 'font-awesome/css/font-awesome.min.css';
import '@atrocit/scl/build/index.css';
import ApolloInjector from './ApolloInjector';
import AuthManager from './auth/AuthManager';
import Routes from './Routes';
import LoadingScreen from './LoadingScreen';
import { HotkeysProvider } from 'react-hotkeys-hook';
import ConfigurationPublicInjector from './ConfigurationPublicInjector';
import DndKitInjector from './DndKitInjector';

function App() {
	return <Suspense fallback={<LoadingScreen />}>
		<ApolloInjector>
			<ConfigurationPublicInjector>
				<HotkeysProvider>
					<AuthManager>
						<DndKitInjector>
							<Routes />
						</DndKitInjector>
					</AuthManager>
				</HotkeysProvider>
			</ConfigurationPublicInjector>
		</ApolloInjector>
	</Suspense>;
}

export default App;
