/**
 * This function is a noop, but can be called to check if the type of a variable is what you expect it to be.
 * It is particularly useful when adding it to the end of matching values of an enum (or union type) to confirm you've
 * handled all cases. If ever a case is added in the future, the TypeScript compiler will complain about this.
 *
 * Example:
 * type MyType = 'A' | 'B';
 * const myVar: MyType = 'A';
 *
 * if (myVar == 'A') {
 *    // Do something
 * } else if (myVar == 'B') {
 *    // Do something else
 * } else {
 *     expectType<never, typeof myVar>();
 * }
 *
 * If in the above example MyType is expanded to type MyType = 'A' | 'B' | 'C', the TypeScript compiler will complain in
 * the else step, since myVar at that point will NOT be of type never
 */
export function expectType<T, V extends T>() {
	throw new Error('This should never happen. If you see this error, the developer has forgotten to implement something');
}