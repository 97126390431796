import { Button, ButtonArea, Form, LoadingOverlayTrigger, Modal } from '@atrocit/scl';
import { gql, useMutation } from '@apollo/client';

export default function ConfirmMarkWarningDismissedModal({ warningId, onClose }) {
	const [ dismissWarning, dismissWarningMutation ] = useMutation(gql`mutation Mutation($id: ID!) {
			dismissWarning(id: $id) { id }
	}`);

	return <Modal isOpen={true} title="Dossier markeren als OK">
		<Form onSubmit={() => { dismissWarning({ variables: { id: warningId } }).finally(() => onClose()); }}>
			{dismissWarningMutation.loading && <LoadingOverlayTrigger />}
			<p style={{ maxWidth: 'var(--u-384)', margin: 0 }}>Weet je zeker dat je dit dossier wilt markeren als OK? Hiermee verdwijnt de waarschuwing, en worden voor dit dossier in de toekomst geen nieuwe waarschuwingen gegenereerd</p>
			<ButtonArea>
				<Button onClick={() => onClose()}>Annuleren</Button>
				<Button type="submit" level="primary"><span className="fa fa-check" />&nbsp; Markeer als OK</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}