import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import About from './about/About';
import Login from './auth/Login';
import atrocitLogo from '../assets/atrocit-logo-1024.png';
import AcceptMagicLink from './auth/AcceptMagicLink';
import { SideBar, SideBarLink } from '@atrocit/scl';
import ConfigurationInjector from './ConfigurationInjector';
import bgImg from '../assets/backgrounds/bg-docs-1.png';
import DocumentSearch from './documentsearch/DocumentSearch';
import ViewDossier from './documentsearch/ViewDossier';
import WarningsOverview from './warnings/WarningsOverview';
import HardErrorsOverview from './harderrors/HardErrorsOverview';

export default function RoutesApp() {
	const user = useContext(UserContext);

	return <BrowserRouter>
		{user == null && <div className="auth">
			<div className="auth-bg-img-wrapper">
				<img src={bgImg} className="auth-bg-img" alt="" />
			</div>
			<div className="login-logo">
				<img src={atrocitLogo} alt="Atrocit Logo" className="atrocit-logo" />
			</div>
			<div className="login-frame">
				<Routes>
					<Route element={<AcceptMagicLink />} path="/magiclink/:token" />
					<Route element={<Login />} path="*" />
				</Routes>
			</div>
		</div>}
		{user != null && <div className="app">
			<ConfigurationInjector>
				<SideBar shortAppName="ato-customs-tool">
					<div>
						<div className="app-logo-area" />
					</div>
					<SideBarLink label="Dossiers" icon="book" to="/documents" />
					<SideBarLink label="Waarschuwingen" icon="warning" to="/warnings" />
					<SideBarLink label="Afkeur" icon="times-circle" to="/harderrors" />

					<div style={{ flex: 1 }} />
					{/* Stuff at bottom of sidebar */}
				</SideBar>
				<div className="app-content">

					<Routes>
						<Route element={<DocumentSearch />} path="/documents" />
						<Route element={<ViewDossier />} path="/documents/:query" />
						<Route element={<WarningsOverview />} path="/warnings" />
						<Route element={<HardErrorsOverview />} path="/harderrors" />

						<Route element={<About />} path="/about" />
						<Route element={<Navigate to="/documents" replace />} path="/*" />
					</Routes>
				</div>
			</ConfigurationInjector>
		</div>}
	</BrowserRouter>;
}