import { Button, ButtonArea, Form, InputField, WithLabel } from '@atrocit/scl';
import Page from '../lib/page/Page';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../lib/page/PageTitle';

export default function DocumentSearch() {
	const navigate = useNavigate();
	const [ query, setQuery ] = useState('');

	return <Page>
		<Form onSubmit={() => {
			navigate('/documents/' + query);
		}}>
			<PageTitle title="Dossier zoeken" />

			<p style={{ marginTop: '0' }}>Vul een volledig dossier- of containernummer in om het dossier te bekijken.</p>

			<WithLabel label="Zoeken">
				<InputField placeholder="ABCU 123456 0" onChange={setQuery} value={query} />
			</WithLabel>

			<ButtonArea>
				<Button type="submit" level="primary">Openen&nbsp;&nbsp; <span className="fa fa-chevron-circle-right" /></Button>
			</ButtonArea>
		</Form>
	</Page>;
}