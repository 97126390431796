import { DndContext, PointerSensor, useSensor, useSensors, pointerWithin, rectIntersection } from '@dnd-kit/core';

function customCollisionDetectionAlgorithm(args) {
	const pointerCollisions = pointerWithin(args);
	if (pointerCollisions.length > 0) {
		return pointerCollisions;
	}
	return rectIntersection(args);
}
export default function DndKitInjector({ children }) {
	const s = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 20,
			},
		}),
	);

	return <DndContext sensors={s} collisionDetection={customCollisionDetectionAlgorithm}>
		{children}
	</DndContext>;
}