import { useCallback, useEffect, useState } from 'react';

export default function ContainerNumber({ value }: { value?: string }) {
	const [ animating, setAnimating ] = useState(false);

	useEffect(() => {
		if (!animating) return;
		const timeout = setTimeout(() => setAnimating(false), 500);
		return () => clearTimeout(timeout);
	}, [ animating ]);

	const onDoubleClick = useCallback((event: any) => {
		if (value && navigator.clipboard) navigator.clipboard.writeText(value);
		setAnimating(true);
		event.preventDefault();
		event.stopPropagation();
		return false;
	}, [ value ]);

	if (value == null || value.length != 11) return <span style={{ userSelect: 'none' }} onDoubleClick={onDoubleClick}>{value}</span>;

	const [ containerNumber, prefix, serial, checkDigit ] = Array.prototype.slice.call(/([A-Za-z]{4})\s?([0-9]{6})\s?([0-9])/.exec(value));
	if (containerNumber != null) {
		return <span className={"container-number" + (animating ? ' container-number-animating' : '')} style={{ userSelect: 'none' }} onDoubleClick={onDoubleClick}>
			<span className="container-number-prefix">{prefix}</span>
			<span className="container-number-serial">{serial}</span>
			<span className="container-number-checkdigit">{checkDigit}</span>
		</span>;
	} else {
		return <span className={"container-number" + (animating ? ' container-number-animating' : '')} style={{ userSelect: 'none' }} onDoubleClick={onDoubleClick}>{value}</span>;
	}
}