import { useEffect, useState } from 'react';
import { Form, WithLabel, InputField, Button, LoadingOverlay, ErrorBanner } from '@atrocit/scl';
import ButtonArea from '../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';

type LoginStatus = 'EMAIL_SENT' | 'EMAIL_UNKNOWN' | 'CONNECTION_ERROR' | 'NOT_SENT';

export default function Login() {
	const [ email, setEmail ] = useState<string>('');
	const [ status, setStatus ] = useState<LoginStatus>('NOT_SENT');

	const [ loginWithMagicLink, loginWithMagicLinkMutation ] = useMutation(gql`mutation Mutation($email: String!) {
		loginWithMagicLink(email: $email)
	}`);

	useEffect(() => {
		if (BroadcastChannel == null) return;
		const bc = new BroadcastChannel('auth');
		bc.onmessage = (event) => {
			if (event.data == null) return;
			const { type } = event.data;
			if (type == 'login' || type == 'logout') {
				window.location.reload();
			}
		};
		return () => bc.close();
	}, []);

	return <Form onSubmit={() => {
		setStatus('NOT_SENT');
		loginWithMagicLink({ variables: { email } })
			.then(result => {
				if (result.data?.loginWithMagicLink) {
					setStatus('EMAIL_SENT');
				} else {
					setStatus('EMAIL_UNKNOWN');
				}
			})
			.catch(() => setStatus('CONNECTION_ERROR'));
	}}>
		{loginWithMagicLinkMutation.loading && <LoadingOverlay />}

		{status == 'EMAIL_SENT'
			? <span>An e-mail has been sent to the provided e-mail address with a link. Clicking that link will automatically log you in.</span>
			: <>
				{status == 'CONNECTION_ERROR' && <><ErrorBanner>Something went wrong while logging in</ErrorBanner><br /></>}
				{status == 'EMAIL_UNKNOWN' && <><ErrorBanner>There is no user registered with e-mail address</ErrorBanner><br /></>}

				<WithLabel label="E-mail">
					<InputField type="email" onChange={setEmail} value={email} autoFocus={true} />
				</WithLabel>

				<ButtonArea>
					<Button type="submit" level="primary">Login &nbsp;<span className="fa fa-chevron-circle-right" /></Button>
				</ButtonArea>
			</>}
	</Form>;
}