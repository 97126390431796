import { ConfigPublicContext } from '../context/ConfigContext';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

export default function ConfigurationPublicInjector({ children }) {
	const configQuery = useQuery(gql`{
        configurationPublic {
            environmentName,
            customCss,
            cowbirdUrl,
        }
    }`);
	const configPublic = configQuery.data?.configurationPublic ?? null;

	useEffect(() => {
		if (configPublic?.customCss == null) return;
		const style = document.createElement('style');
		style.innerHTML = configPublic.customCss;
		document.head.appendChild(style);
		return () => document.head.removeChild(style);
	}, [ configPublic?.customCss ]);

	return <ConfigPublicContext.Provider value={configPublic}>{children}</ConfigPublicContext.Provider>;
}