import { DateTime } from 'luxon';
import { useMemo } from 'react';

type UnderstandableDateTimeValue = undefined | null | number | string | Date | DateTime;

interface FormattedTimeProps {
	value: UnderstandableDateTimeValue,
}

export default function FormattedTime({ value }: FormattedTimeProps) {
	const date = useMemo(() => parseValueToLuxon(value), [ value ]);
	return <>{date == null ? '-' : date.toFormat('HH:mm')}</>;
}

function parseValueToLuxon(value: UnderstandableDateTimeValue) {
	if (value == null) return null;
	if (value instanceof Date) return DateTime.fromJSDate(value);
	if (value instanceof DateTime) return value;
	if (typeof value == 'string') return DateTime.fromISO(value);
	if (typeof value == 'number') return DateTime.fromMillis(value);
	return null;
}