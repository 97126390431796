import React from 'react';

type ButtonAreaProps = {
	children: React.ReactNode,
};

export default function ButtonArea({ children }: ButtonAreaProps) {
	return <div className="button-area">
		{children}
	</div>;
}