import React from 'react';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

export const logoutListeners: (() => any)[] = [];

// HTTP stuff
const httpLink = new HttpLink({ uri: '/graphql', credentials: 'include' });
const logoutLink = onError(({ networkError }) => {
	// Whenever we receive a 401, mark the user as logged out
	if (networkError != null && 'statusCode' in networkError && networkError.statusCode == 401) logoutListeners.forEach(fn => fn());
});

// Websocket stuff
const [ host ] = window.location.host.split(':');
const protocol = window.location.protocol.includes('https') ? 'wss' : 'ws';
const wsLink = new GraphQLWsLink(createClient({
	url: protocol + '://' + host + (host == 'localhost' ? ':5000' : '') + '/graphql',
	shouldRetry: () => true,
	retryWait: num => new Promise(resolve => setTimeout(resolve, Math.pow((num - 1), 1.4) * 1000)), // exponential backoff
	retryAttempts: Infinity,
}));

// Combined HTTP / websocket stuff
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	logoutLink.concat(httpLink),
);

export const myApolloClient = new ApolloClient({
	uri: '/graphql',
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-and-network',
			refetchWritePolicy: 'overwrite',
			errorPolicy: 'all',
		},
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
		mutate: {
			errorPolicy: 'all',
		},
	},
	link: splitLink,
});

type ApolloInjectorProps = {
	children: React.ReactNode,
};

export default function ApolloInjector({ children }: ApolloInjectorProps) {
	return <ApolloProvider client={myApolloClient}>
		{children}
	</ApolloProvider>;
}
