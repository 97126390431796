import React from 'react';

// interface PageProps {
// 	fullWidth?: boolean,
// 	children?: React.ReactNode,
// }

export default function Page({ fullWidth = false, children, ...props }: any) {
	return <div className={"page" + (fullWidth ? ' page-fw' : '')} {...props}>
		{children}
	</div>;
}