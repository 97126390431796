import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import LoadingScreen from '../LoadingScreen';
import { logoutListeners } from '../ApolloInjector';
import { UserContext } from '../../context/UserContext';

type AuthManagerProps = {
	children?: React.ReactNode,
}

export default function AuthManager({ children }: AuthManagerProps) {
	const userQuery = useQuery(gql`{
		whoami {
			id, email, firstName, fullName
		}
	}`);
	const user = userQuery?.data?.whoami;

	// Register a listener which refetches the user whenever a 401 is detected
	useEffect(() => {
		const listener = () => userQuery.refetch();
		logoutListeners.push(listener);
		return () => {
			logoutListeners.splice(logoutListeners.indexOf(listener), 1);
		};
	}, [ userQuery ]);

	// Depending on the state, show either the loading screen, login screen, or the rest of the application
	// We should probably leave the login vs other screen as part of the react-router bits
	if (userQuery.loading) {
		return <LoadingScreen />;
	} else {
		return <UserContext.Provider value={user}>
			{children}
		</UserContext.Provider>;
	}
}