import { gql, useQuery } from '@apollo/client';
import Page from '../lib/page/Page';
import { Button, InputField, Pagination } from '@atrocit/scl';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ConfirmMarkWarningDismissedModal from './ConfirmMarkWarningDismissedModal';
import PaginationWrapper from '../lib/tables/PaginationWrapper';
import FormattedFullDate from '../lib/display/FormattedFullDate';
import FormattedTime from '../lib/display/FormattedTime';
import ContainerNumber from '../lib/display/ContainerNumber';

const PAGE_SIZE = 25;

export default function WarningsOverview() {
	const [ page, setPage ] = useState(1);
	const [ query, setQuery ] = useState('');
	const [ dismissWarningModalOpen, setDismissWarningModalOpen ] = useState(null);

	const warningsQuery = useQuery(gql`query Query($offset: Int!, $limit: Int!, $query: String) {
        warnings(offset: $offset, limit: $limit, query: $query) {
            items {
                id, dossierNr, containerNr, state, createdAt, snapshot
            }
            total,
        }
    }`, { variables: { offset: (page - 1) * PAGE_SIZE, limit: page * PAGE_SIZE, query: query }, pollInterval: 10 * 1000 });
	const warnings = warningsQuery?.data?.warnings?.items ?? [];
	const total = warningsQuery?.data?.warnings?.total ?? 0;

	return <Page>
		{dismissWarningModalOpen != null && <ConfirmMarkWarningDismissedModal warningId={dismissWarningModalOpen} onClose={() => { setDismissWarningModalOpen(null); warningsQuery.refetch(); }} />}
		<h1>Waarschuwingen</h1>
		<InputField type="search" value={query} onChange={setQuery} placeholder="Zoeken..." />
		<table className="table">
			<colgroup>
				<col width="100" />
				<col width="140" />
				<col width="*" />
				<col width="110" />
				<col width="150" />
				<col width="10" />
			</colgroup>
			<thead>
				<tr>
					<th>Dossier</th>
					<th>Container</th>
					<th>Gemaakt op</th>
					<th>Gebr.code</th>
					<th>ETIS-gebruiker</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{warnings.map(w => {
					return <tr key={w.id}>
						<td><Link to={`/documents/${w.dossierNr}`} target="_blank">{w.dossierNr}</Link></td>
						<td style={{ fontFamily: 'monospace' }}><ContainerNumber value={w.containerNr} /></td>
						<td><FormattedFullDate value={w.createdAt} /> - <FormattedTime value={w.createdAt} /></td>
						<td><ExtractUsernamesFromSnapshot snapshotJsonString={w.snapshot} /></td>
						<td><ExtractEtisUserFromSnapshot snapshotJsonString={w.snapshot} /></td>
						<td>
							<Button onClick={() => setDismissWarningModalOpen(w.id)}><span className="fa fa-check" /></Button>
						</td>
					</tr>;
				})}
			</tbody>
		</table>

		<PaginationWrapper>
			<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={total} />
		</PaginationWrapper>
	</Page>;
}

function ExtractUsernamesFromSnapshot({ snapshotJsonString }) {
	if (snapshotJsonString == null) return null;
	if (snapshotJsonString === '') return null;
	try {
		const snapshot = JSON.parse(snapshotJsonString);
		if (snapshot == null) return null;

		return <>
			{[ ...new Set(snapshot?.documents?.filter(d => d.fidInout == 'UITSL').map(d => d.fidCreationWuUser == null || d.fidCreationWuUser.trim().length == 0 ? d.fidCreationGbCode : d.fidCreationWuUser)) ].join(', ')}
		</>;
	} catch (e) { /* Ignore */
	}
	return null;
}

function ExtractEtisUserFromSnapshot({ snapshotJsonString }) {
	if (snapshotJsonString == null) return null;
	if (snapshotJsonString === '') return null;
	try {
		const snapshot = JSON.parse(snapshotJsonString);
		if (snapshot == null) return null;

		return <>
			{[ ...new Set(snapshot?.documents?.filter(d => d.etisUsrName != null).map(d => d.etisUsrName)) ].join(', ')}
		</>;
	} catch (e) { /* Ignore */
	}
	return null;
}