import { ConfigContext } from '../context/ConfigContext';
import { gql, useQuery } from '@apollo/client';

export default function ConfigurationInjector({ children }) {
	const configQuery = useQuery(gql`{
		configuration {
            environmentName,
			customCss,
			cowbirdUrl,
        }
	}`);
	const config = configQuery.data?.configuration ?? null;

	return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}