import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import Page from '../lib/page/Page';
import FormattedDate from '../lib/display/FormattedDate';
import FormattedTime from '../lib/display/FormattedTime';
import { Button, LoadingOverlay } from '@atrocit/scl';
import ContainerNumber from '../lib/display/ContainerNumber';
import { expectType } from '../../utils/typescript';
import { useEffect } from 'react';

function docNrsEqual(a: string | null, b: string | null) {
	const aMod = (a || '').trim().toUpperCase();
	const bMod = (b || '').trim().toUpperCase();
	return aMod == bMod;
}

function prevDocValidValidity(documents: any[], curDoc: any): CheckResult {
	if (curDoc.fidInout?.trim() != 'UITSL') return 'NOT_CHECKED';
	return (documents.find(d => (d.fidInout || '').trim() == 'INSL' && docNrsEqual(d.fidNr, curDoc.fidRefPrevDoc)) != null ? 'SUCCESS' : 'FAIL');
}

function nullBooleanToCheckResult(input?: boolean | null): CheckResult {
	if (input == null) return 'NOT_CHECKED';
	return input ? 'SUCCESS' : 'FAIL';
}

function prevNrInPdfCheck(doc: any): CheckResult {
	if (doc.pdfContainsPrevNr == null) return 'NOT_CHECKED';
	if (doc.pdfContainsPrevNr) {
		if (doc.fidRefPrevDoc == null || doc.fidRefPrevDoc.trim().length == 0) return 'WARNING'; // ok-ish, but user hasn't filled it out properly
		return 'SUCCESS';
	} else {
		return 'FAIL';
	}
}

type CheckResult = 'NOT_CHECKED' | 'SUCCESS' | 'WARNING' | 'FAIL';

export default function ViewDossier() {
	const { query } = useParams();

	const documentsQuery = useQuery(gql`query Query($dossierNr: String!) {
		documentsForDossier(dossierNr: $dossierNr) {
			fidFiche,
			fiCt,
			fidSeqNr,
			fidType,
			fidNr,
			fidCreationDate,
			fidInout,
			fidRefPrevDoc,
			fidNrPackage,
			fidGrossWeight,
			fiaFilename,
			fiaInfo,
			fiaBlob,
			pdfContainsNr,
			pdfContainsPrevNr,
			pdfContainsCorrectSummaryCode,
			fidCreationWuUser,
			fidCreationGbCode,
			etisUsrName,
			etisEmail,
		}
	}`, { variables: { dossierNr: query } });
	const documents = (documentsQuery?.data?.documentsForDossier || []);

	const warningQuery = useQuery(gql`query Query($dossierNr: String!) {
		warningsForDossier(dossierNr: $dossierNr) {
			id,
			createdAt,
		}
	}`, { variables: { dossierNr: query } });
	const openWarning: any | undefined = (warningQuery?.data?.warningsForDossier || [])[0];

	const [ dismissWarning, dismissWarningMutation ] = useMutation(gql`mutation Mutation($id: ID!) {
		dismissWarning(id: $id) { id }
	}`);

	useEffect(() => {
		if (documents == null || documents.length == 0) return;
		const originalTitle = document.title;

		const containerNumber = documents[0].fiCt;
		if (containerNumber == null || containerNumber.length < 11) return;
		const [ _, prefix, serial, checkDigit ] = Array.prototype.slice.call(/([A-Za-z]{4})\s?([0-9]{6})\s?([0-9])/.exec(containerNumber));

		document.title = prefix + ' ' + serial + ' ' + checkDigit + ' - ' + originalTitle;
		return () => {
			document.title = originalTitle;
		};
	}, [ documents ]);

	return <Page fullWidth={true} style={{ height: '100%' }}>
		{documentsQuery.loading && <LoadingOverlay />}
		{!documentsQuery.loading && documents.length > 0 &&
			<div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 'var(--u-16)' }}>
					<div>
						<h1 style={{ margin: 0, padding: 0 }}>Dossier {documents[0]?.fidFiche}</h1>
					</div>
					<div>
						{openWarning && <Button disabled={warningQuery?.loading || dismissWarningMutation.loading} onClick={() => dismissWarning({ variables: { id: openWarning.id } }).finally(() => warningQuery.refetch())} level="primary"><span className="fa fa-check" />&nbsp; Markeer als OK</Button>}
						{openWarning == null && <span style={{ fontStyle: 'italic' }}>Geen open waarschuwingen voor dit dossier</span>}
					</div>
				</div>
				<table className="table">
					<colgroup>
						<col width="100" />
						<col width="130" />
						<col width="40" />
						<col width="50" />
						<col width="110" />
						<col width="150" />
						<col width="*" />
						<col width="190" />
						<col width="110" />
						<col width="70" />
						<col width="190" />
						<col width="70" />
						<col width="80" />
						<col width="*" />
						<col width="120" />
						<col width="150" />
						<col width="180" />
					</colgroup>
					<thead>
						<tr>
							<th>Dossier</th>
							<th>Container</th>
							<th>Seq</th>
							<th>Type</th>
							<th>Gebruiker</th>
							<th>ETIS naam</th>
							<th>ETIS email</th>
							<th>MRN</th>
							<th>Datum</th>
							<th>In/Uit</th>
							<th>Voorg.nr</th>
							<th className="tbl-align-right">Colli</th>
							<th className="tbl-align-right">Gewicht</th>
							<th>PDF bestandsnaam</th>
							<th>MRN in PDF</th>
							<th>Voorg.nr in PDF</th>
							<th>Sum.Aangiftetype OK</th>
						</tr>
					</thead>
					<tbody>
						{documents.map((doc: any, idx: number) => <tr key={idx}>
							<td>{doc.fidFiche}</td>
							<td style={{ fontFamily: 'monospace' }}><ContainerNumber value={doc.fiCt?.trim()} /></td>
							<td>{doc.fidSeqNr}</td>
							<td>{doc.fidType}</td>
							<td>{doc.fidCreationWuUser == null || doc.fidCreationWuUser.trim().length == 0 ? doc.fidCreationGbCode : doc.fidCreationWuUser}</td>
							<td>{doc.etisUsrName || '-'}</td>
							<td><a href={doc.etisEmail != null ? "mailto:" + doc.etisEmail : undefined} target="_blank" rel="noreferrer">{doc.etisEmail || '-'}</a></td>
							<td>{doc.fidNr}</td>
							<td><FormattedDate value={doc.fidCreationDate} /> - <FormattedTime value={doc.fidCreationDate} /></td>
							<td>{doc.fidInout}</td>
							<ValidationTd
								value={prevDocValidValidity(documents, doc)}
								okText={doc.fidRefPrevDoc}
								nokText={'Ontbreekt'} />
							<td className="tbl-align-right">{doc.fidNrPackage}</td>
							<td className="tbl-align-right">{doc.fidGrossWeight}</td>
							<ValidationTd
								value={doc.fidInout?.trim() !== 'UITSL' ? 'NOT_CHECKED' : ((doc.fiaFilename != null && doc.fiaFilename.trim().length > 0) ? 'SUCCESS' : 'FAIL')}
								okText={doc.fiaFilename?.length > 15 ? doc.fiaFilename?.slice(0, 5) + '[...]' + doc.fiaFilename?.slice(-10) : doc.fiaFilename}
								nokText={'PDF mist'} />
							<ValidationTd
								value={nullBooleanToCheckResult(doc.pdfContainsNr)}
								okText="MRN in PDF"
								nokText="MRN mist" />
							<ValidationTd
								value={doc.fidInout?.trim() !== 'UITSL' ? 'NOT_CHECKED' : prevNrInPdfCheck(doc)}
								okText="Voorg. nr in PDF"
								warnText="Voorg. nr in PDF (maar niet ingevuld door gebruiker)"
								nokText="Voorg. nr mist" />
							<ValidationTd
								value={doc.fidInout?.trim() !== 'UITSL' ? 'NOT_CHECKED' : nullBooleanToCheckResult(doc.pdfContainsCorrectSummaryCode)}
								okText="Sum.Aangiftetype OK"
								nokText="Sum.Aangiftetype FOUT" />
						</tr>)}
					</tbody>
				</table>

				<div style={{ paddingTop: 'var(--u-24)', flex: 1, minHeight: '600px', display: 'flex' }}>
					{documents.filter((d: any) => d.fiaBlob != null).map((d: any) => {
						return <div style={{ paddingTop: 'var(--u-16)', flex: 1, display: 'flex', flexDirection: 'column' }}>
							<h2>{d.fidNr} - {d.fiaFilename}</h2>
							<iframe src={'data:application/pdf;base64,' + d.fiaBlob + '#:~:text=' + (d.fidRefPrevDoc + '').trim()} style={{ flex: 1 }} />
						</div>;
					})}
				</div>
			</div>}
		{!documentsQuery.loading && documents.length == 0 && <h1>Geen documenten gevonden voor {query}</h1>}
	</Page>;
}

function ValidationTd({ value, okText, warnText, nokText }: { value: CheckResult, okText: string, warnText?: string, nokText: string }) {
	if (value == 'NOT_CHECKED') {
		return <td></td>;
	} else if (value == 'SUCCESS') {
		return <td style={{ background: 'var(--col-green-100)', color: 'var(--col-green-900)' }}><span className="fa fa-check-circle" style={{ color: 'var(--col-green-600)' }} />&nbsp; {okText}</td>;
	} else if (value == 'FAIL') {
		return <td style={{ background: 'var(--col-red-100)', color: 'var(--col-red-900)' }}><span className="fa fa-times-circle" style={{ color: 'var(--col-red-600)' }} />&nbsp; {nokText}</td>;
	} else if (value == 'WARNING') {
		return <td style={{ background: 'var(--col-orange-100)', color: 'var(--col-orange-900)' }}><span className="fa fa-warning" style={{ color: 'var(--col-orange-500)' }} />&nbsp; {warnText ?? okText}</td>;
	} else {
		expectType<never, typeof value>();
		throw new Error('Unreachable');
	}
}