import { useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { LoadingOverlay, ErrorBanner } from '@atrocit/scl';
import { useEffect, useState } from 'react';

// It's ugly to have this be a global, but you CAN'T put it as ref in the React component because
//  ever since React 18 decided you can just remount and do useEffect() twice willy-nilly, this will
//  really fuck with the backend if we submit a token twice, and invalidate our session on the second request.
let acceptingTokenLock = false;

export default function AcceptMagicLink() {
	const { token } = useParams();
	const [ error, setError ] = useState(false);

	const [ acceptMagicLink, acceptMagicLinkMutation ] = useMutation(gql`mutation Mutation($token: String!) {
		acceptMagicLink(token: $token)
	}`);

	useEffect(() => {
		if (acceptingTokenLock) return;
		if (token == null || token == '' || token.trim().length == 0) return;
		acceptingTokenLock = true;
		acceptMagicLink({ variables: { token } })
			.then(({ data }) => {
				if (data.acceptMagicLink) {
					if (BroadcastChannel != null) {
						const bc = new BroadcastChannel('auth');
						bc.postMessage({ type: 'login' });
					}
					return window.location.reload();
				} else {
					// eslint-disable-next-line no-console
					console.warn('Login fail', data);
					setError(true);
				}
			}).catch(e => {
				// eslint-disable-next-line no-console
				console.error('Login request fail', e);
				setError(true);
			});
	}, [ token ]);

	return <>
		{acceptMagicLinkMutation.loading && <LoadingOverlay />}
		{!error && <div style={{ minHeight: '128px', width: '100%' }}></div>}
		{error && <ErrorBanner>Something went wrong while logging in, maybe the token was already used</ErrorBanner>}
	</>;
}